import React, { useRef } from 'react';
import Intersect from 'components/Intersect';

import { noop } from 'helpers/fp';

import styles from './styles.scss';

const onTopIntersection = ({
  entries,
  bottomSentinelRef,
  offsetTop,
  offsetBottom,
  isTopLoaded,
  intersected,
  onIntersection,
  onLoad
}) => {
  entries.forEach(entry => {
    offsetTop.current = entry.boundingClientRect.top;

    const bottomSentinel = bottomSentinelRef.current;

    if (bottomSentinel) {
      offsetBottom.current = bottomSentinel.getBoundingClientRect().top;
    }

    if (offsetTop.current === null || offsetBottom.current === null) return;

    // patch for onload
    if (!onLoad && !isTopLoaded.current) {
      isTopLoaded.current = true;
      return;
    }

    if (offsetTop.current <= 0 && offsetBottom.current >= 0 && !intersected.current) {
      onIntersection(true, 'top');
      intersected.current = true;
    } else if (offsetTop.current >= 0 && offsetBottom.current >= 0 && intersected.current) {
      onIntersection(false, 'top');
      intersected.current = false;
    }
  });
};

const onBottomIntersection = ({
  entries,
  offsetTop,
  offsetBottom,
  topSentinelRef,
  isBottomLoaded,
  intersected,
  onIntersection,
  onLoad
}) => {
  entries.forEach(entry => {
    offsetBottom.current = entry.boundingClientRect.top;

    const topSentinel = topSentinelRef.current;

    if (topSentinel) {
      offsetTop.current = topSentinel.getBoundingClientRect().top;
    }

    if (offsetTop.current === null || offsetBottom.current === null) return;

    // patch for onload
    if (!onLoad && !isBottomLoaded.current) {
      isBottomLoaded.current = true;
      return;
    }

    if (offsetTop.current <= 0 && offsetBottom.current >= 0 && !intersected.current) {
      onIntersection(true, 'bottom');
      intersected.current = true;
    } else if (offsetTop.current <= 0 && offsetBottom.current <= 0 && intersected.current) {
      onIntersection(false, 'bottom');
      intersected.current = false;
    }
  });
};

const SentinelIntersect = props => {
  const {
    children,
    config,
    topSentinelClass = '',
    bottomSentinelClass = '',
    onIntersection = noop,
    onLoad = true
  } = props;

  const intersected = useRef(false);
  const offsetTop = useRef(null);
  const offsetBottom = useRef(null);

  // loaded flags are patch only on iOS
  // onIntersection behavior is not expected
  // on load especially if routes were changed
  const isTopLoaded = useRef(false);
  const isBottomLoaded = useRef(false);

  const topSentinelRef = useRef();
  const bottomSentinelRef = useRef();

  return (
    <>
      <Intersect
        config={config}
        onIntersection={entries =>
          onTopIntersection({
            entries,
            bottomSentinelRef,
            offsetTop,
            offsetBottom,
            isTopLoaded,
            intersected,
            onIntersection,
            onLoad
          })
        }>
        <div
          className={`${styles.sentinel} ${styles['sentinel--top']} ${topSentinelClass}`}
          ref={topSentinelRef}
        />
      </Intersect>
      {children}
      <Intersect
        config={config}
        onIntersection={entries =>
          onBottomIntersection({
            entries,
            props,
            offsetTop,
            offsetBottom,
            topSentinelRef,
            isBottomLoaded,
            intersected,
            onIntersection,
            onLoad
          })
        }>
        <div
          className={`${styles.sentinel} ${styles['sentinel--bottom']} ${bottomSentinelClass}`}
          ref={bottomSentinelRef}
        />
      </Intersect>
    </>
  );
};

export default SentinelIntersect;
