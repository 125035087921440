import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { stripParagraphTag, capitalize } from 'helpers/text';
import { socialClick } from 'helpers/dataLayer';
import { get } from 'helpers/utilities';

import Icon from 'components/Icon';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const Contact = (
  { contactTitle, contactOptions, socials, isMinified, contactHeader, contactEmail, contactNumber },
  ref
) => {
  const contactInfo = isMinified ? [{ contactTitle, contactEmail, contactNumber }] : contactOptions;

  const contactContainerClass = cx('contact__columns', {
    'contact__columns--minified': isMinified,
    'contact__columns--special': !isMinified && contactInfo.length === 2,
    'contact__columns--general': !isMinified && contactInfo.length > 2
  });

  const contactTitleClass = cx('contact__title', {
    'contact__title--minified': isMinified,
    'contact__title--general': !isMinified
  });

  return (
    <footer
      className={`${styles.contact} ${isMinified && styles.contact__minified} ${
        isMinified && 'grid-container'
      }`}
      aria-label="Footer contact"
      ref={ref}>
      <h1
        className={`${contactTitleClass}`}
        dangerouslySetInnerHTML={{
          __html: stripParagraphTag(get(isMinified ? contactHeader : contactTitle, 'content'))
        }}
      />
      <ul className={contactContainerClass} aria-label="Contacts">
        {contactInfo.map((contact, index) => (
          <li
            key={index}
            className={styles.contact__columns__item}
            aria-label={`Contact item ${index + 1}`}>
            <h2
              className={styles.contact__subTitle}
              dangerouslySetInnerHTML={{ __html: get(contact, 'contactTitle') }}
            />

            {contact?.contactEmail ? (
              <a
                href={`mailto:${get(contact, 'contactEmail')}?subject=${
                  get(contact, 'contactEmail') === 'newbiz@codeandtheory.com'
                    ? 'New Business Inquiry [Source: Agency Website]'
                    : 'Press Inquiry [Source: Agency Website]'
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.contact__email}
                aria-live="assertive"
                aria-label={`${get(contact, 'contactEmail')}. Click to mail.`}>
                <span>{get(contact, 'contactEmail')}</span>
              </a>
            ) : null}

            {contact?.contactAddress?.content ? (
              <span
                className={styles.contact__address}
                dangerouslySetInnerHTML={{ __html: get(contact, 'contactAddress.content') }}
              />
            ) : null}

            {contact?.contactLink?.[0] &&
            Boolean(contact?.contactLink[0]?.title) &&
            Boolean(contact?.contactLink[0]?.uri) ? (
              <span className={styles.contact__cta}>
                <Link
                  to={{
                    pathname: `/${get(contact, 'contactLink[0].uri')}`,
                    state: { componentLink: get(contact, 'contactComponentLink') }
                  }}
                  className={styles.contact__ctaLink}
                  title={get(contact, 'contactCtaTitle')}>
                  {get(contact, 'contactCtaTitle')}
                </Link>
              </span>
            ) : null}

            {get(contact, 'contactNumber') ? (
              <span className={styles.contact__contactNumber}>
                <a href={`tel:${get(contact, 'contactNumber')}`}>{get(contact, 'contactNumber')}</a>
              </span>
            ) : null}
          </li>
        ))}
        {socials && socials.length ? (
          <li className={styles.contact__socials}>
            {socials.map((social, index) => (
              <a
                href={social.socialLinkUrl}
                key={index}
                className={styles.contact__social}
                title={`Follow us on ${capitalize(get(social, 'socialLinkType'))}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={socialClick(get(social, 'socialLinkType'), 'footer social click')}>
                <Icon color="white" name={get(social, 'socialLinkType')} />
              </a>
            ))}
          </li>
        ) : null}
      </ul>
    </footer>
  );
};

export default React.forwardRef(Contact);
