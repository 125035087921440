import React, { useRef, useEffect, useCallback } from 'react';
import SentinelIntersect from 'components/SentinelIntersect';

// hooks
import useNavigation from '../../hooks/useNavigation';

const navThemeMap = {
  black: {
    theme: 'black',
    gradient: false
  },
  white: {
    theme: 'white',
    gradient: false
  },
  header: {
    theme: 'white',
    gradient: true
  }
};

/**
 * Navigatiom theme setter - sets the nav theme
 * base on the onIntersection prop. It will trigger
 * when the children components intersect the topmost
 * part of the browser. Its done using bounding client
 * of both topmost child and bottom most child. The ref
 * of child is drill passed using forwardRef.
 *
 * active - when false blocks the setting of navigation theme with continuously
 *          recording the last theme propagated while true it sets the nav theme
 *          base on the last recorded if any and directly sets when another theme
 *          is propagated. This is a hotfix only when transporting - CCR-3937.
 */
const NavigationThemeSetter = ({ onIntersection, navTheme, children, active = true }) => {
  const { setNavTheme, setNavGradient } = useNavigation();
  // use to record the last value of intersected
  // so when it gets active it will flush the last
  // recorded
  const lastIntersection = useRef();
  const refActive = useRef(active);

  useEffect(() => {
    refActive.current = active;
    if (active && lastIntersection.current) {
      const { theme, gradient } = navThemeMap[navTheme];
      setNavTheme(theme);
      setNavGradient(gradient);
    }
  }, [active]);

  const onSentinelIntersection = useCallback(
    (intersected, sentinel) => {
      lastIntersection.current = intersected;
      if (intersected && refActive.current) {
        const { theme, gradient } = navThemeMap[navTheme];
        setNavTheme(theme);
        setNavGradient(gradient);
        lastIntersection.current = null;
      }
      if (onIntersection) onIntersection({ intersected, sentinel });
    },
    [navTheme, active]
  );

  return (
    <SentinelIntersect config={{ threshold: [0, 1] }} onIntersection={onSentinelIntersection}>
      {children}
    </SentinelIntersect>
  );
};

export const withNavigationThemeSetter =
  ({ onIntersection, navTheme } = {}) =>
  Component =>
    React.forwardRef((props, ref) => (
      <NavigationThemeSetter onIntersection={onIntersection} navTheme={navTheme}>
        {ref ? <Component {...props} ref={ref} /> : <Component {...props} />}
      </NavigationThemeSetter>
    ));

export default NavigationThemeSetter;
