import { get } from 'helpers/utilities';

const buildGTMData = entry => ({
  type: get(entry, 'type.name', ''),
  year: get(entry, 'associatedYear[0].title', ''),
  account: get(entry, 'accountParent[0].title', ''),
  output: get(entry, 'outputCategory[0].title', ''),
  company: get(entry, 'companyCategory[0].title', ''),
  industry: get(entry, 'taxonomy[0].title', '')
});

export default buildGTMData;
