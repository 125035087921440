import React, { useRef, useEffect } from 'react';

import useCombinedRefs from '../../hooks/useCombinedRefs';

const DEFAULT_INTERSECTION_CONFIG = {
  root: null,
  margin: '0px',
  threshold: [0, 1],
  trackVisibility: true,
  delay: 100
};

const Intersect = props => {
  const { children, active = true, onIntersection, config = DEFAULT_INTERSECTION_CONFIG } = props;

  const intersectionObserver = useRef(null);
  const indicatorRef = useCombinedRefs(children.ref);

  const createObserver = () => {
    const { current: indicatorRefCurrent } = indicatorRef;
    if (onIntersection && indicatorRefCurrent) {
      intersectionObserver.current = new IntersectionObserver(onIntersection, config);
      intersectionObserver.current.observe(indicatorRefCurrent);
    }
  };

  useEffect(() => {
    if (active) createObserver();
    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
    };
  }, []);

  return React.cloneElement(children, { ref: indicatorRef });
};

export default Intersect;
